import * as React from "react"

import { links } from "../common/constants"
import Button from "../components/button"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 - Pagina non trovata." />

    <section className="relative py-24 overflow-hidden">
      <div className="container px-4 mx-auto relative">
        <div className="max-w-4xl mx-auto text-center relative z-10">
          <h3 className="text-4xl lg:text-5xl font-heading mb-12 text-blue-500">
            Pagina non trovata.
          </h3>
          <p className="mb-10 text-lg">
            Siamo spiacenti, la pagina che stai cercando non esiste.
          </p>
          <Button anchor={links.home} label="Torna indietro" />
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
